import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'

export default function Thanks() {
  return (
    <Layout pageTitle="Thanks!">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <main>
        <ThanksStyled>
          <h2>Vielen Dank!</h2>
          <p>
            Thank you for contacting me! <br />
            I'll come back to you as soon as possible, usually within a day.
          </p>
        </ThanksStyled>
      </main>
    </Layout>
  )
}

const ThanksStyled = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  padding: 20vmin 0;

  > p {
    text-align: center;
  }
`
